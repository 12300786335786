<template>

  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>


       

          <!--Component table leads-->
          <b-col cols="12">
            <TableList />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import TableList from './TableList.vue'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BCardImg, BCardFooter, BModal, VBModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'



export default {
  components: {
    TableList,
    BModal,
    vSelect,
    BCard,
    BCardImg,
    BCardFooter,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
  data() {
    return {
      isActive: true,
      perPage: 10,
    }
  }
}
</script>



<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
