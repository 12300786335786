<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div class="d-flex align-items-center justify-content-end">
          <b-button variant="primary" to="/leads/leads-add">
            <span class="text-nowrap">Add Lead</span>
          </b-button>
        </div>
      </b-col>
      <b-col cols="12">
        <b-row>
          <b-col col="4">
            <b-form-group label="Lead Name" label-for="h-Order-name">
              <b-form-select
                v-model="selectedName"
                :options="leadName"
                @change="orderFilterChanged()"
              >
                <option value="">All</option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col col="4">
            <b-form-group label="Email" label-for="h-Order-email">
              <b-form-select
                v-model="selectedEmail"
                :options="leadEmail"
                @change="orderFilterChanged()"
              >
                <option value="">All</option>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col col="4" class="d-flex align-items-center">
            <b-button @click="clearFilters"> Clear </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="10" class="my-1">
        <b-form-group
          label="Filter"
          label-cols-sm="8"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="leads"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          :busy="isBusy"
        >
          <template #cell(status)="data">
            <b-badge
              v-if="data.item.status === 2 && data.item.isClient"
              variant="primary"
            >
              Won
            </b-badge>
            <b-badge
              v-else-if="data.item.status === 3 && data.item.isClient"
              variant="primary"
            >
              Direct Customer
            </b-badge>
            <b-badge v-else-if="data.item.status === 0" variant="info">
              New
            </b-badge>
            <b-badge v-else-if="data.item.status === 1" variant="Warning">
              Discussion
            </b-badge>
          </template>
          <template #cell(salesPersonId)="data">
            <p v-if="data.item.salesPersonId === null">-</p>
            <p v-else>{{ getSalePersonName(data.item.salesPersonId) }}</p>
          </template>

          <template #cell(createdOn)="data">
            {{ formatDate(data.item.createdOn) }}
          </template>
          <template #cell(actions)="data">
            <b-button
              variant="primary"
              v-if="data.item.isClient"
              :to="returnToCustomer(data.item.id)"
            >
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span>View</span>
            </b-button>
            <b-button
              v-else
              :to="getLeadEditData(data.item.id)"
              variant="primary"
            >
              <feather-icon icon="Edit2Icon" class="mr-50" /> <span>View</span>
            </b-button>
          </template>
        </b-table>
        <div class="d-flex justify-content-center mb-1">
          <b-spinner
            variant="success"
            label="Spinning"
            block
            v-if="loading"
            style="width: 3rem; height: 3rem"
          ></b-spinner>
        </div>
      </b-col>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
        <b-form-group>
      Showing {{ perPage * (currentPage - 1) + 1}} 
      to {{ Math.min(perPage * currentPage, totalRows) }} 
      of {{ totalRows }} entries
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BSpinner,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BSpinner,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      companyName: "",
      email: "",
      otherName: "",
      surname: "",
      typeDesc: "",
      status: "",
      leads: [],
      isActive: false,
      selectedName: null,
      selectedType: null,
      selectedEmail: null,
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 0,
      currentPage: 0,
      sortBy: "",
      

      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      leadName: [],
      leadEmail: [],
      leadType: [
        { text: "New", value: 0 },
        { text: "Discussion", value: 1 },
        { text: "Won", value: 2 },
        { text: "Direct Customer", value: 3 },
      ],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      // fields to be displayed on table
      fields: [
        { key: "otherName", label: "Lead Name", sortable: true },

        { key: "typeDesc", label: "Lead Type" },
        { key: "email", label: "Email", sortable: true },
        { key: "salesPersonId", label: "Sales Person" },
        { key: "createdOn", label: "Created On", sortable: "true" },
        "status",
        "actions",
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    ...mapGetters("leadModule", {
      leadList: "leads",
      loading: "loading",
    }),
    ...mapGetters("salesPersonModule", {
      SalesPersonList: "salesperson",
      loading: "loading",
    }),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.leadList.length;

  },

  methods: {
    clearFilters() {
      this.selectedName = "";
      this.selectedType = "";
      this.selectedEmail = "";
      this.leads = [...this.leadList];
      this.totalRows = this.leads.length;
      this.currentPage = 1;
    },
    orderFilterChanged() {
      this.leads = this.leadList;
      if (this.selectedName) {
        this.leads = this.leads.filter((obj) => {
          return obj.id === this.selectedName;
        });
      }
      if (this.selectedType) {
        let selectedTypeNumber = Number(this.selectedType);
        this.leads = this.leads.filter((obj) => {
          console.log(obj.status, selectedTypeNumber);
          return obj.status === selectedTypeNumber;
        });
      }
      if (this.selectedEmail) {
        this.leads = this.leads.filter((obj) => {
          return obj.id === this.selectedEmail;
        });
      }

      this.totalRows = this.leads.length;
      this.currentPage = 1;
    },

    formatDate(date) {
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(date).toLocaleDateString("en-US", options);
    },
    getSalePersonName(id) {
      const salesPerson = this.SalesPersonList.find((s) => s.id === id);
      return salesPerson
        ? salesPerson.firstName + " " + salesPerson.lastName
        : "";
    },
    ...mapActions("salesPersonModule", ["getSalesPersonListAction"]),
    ...mapActions("leadModule", ["getLeadListAction", "removeLeadAction"]),

    returnToCustomer(id) {
      return "/customer/customer-view/" + id;
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getLeadEditData(data) {
      return "/leads/leads-view/" + data;
    },

    removeLead(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeLeadAction(id);
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: "Lead has been deleted",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Cancelled",
            text: "Lead not deleted:)",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
  },
  async mounted() {
    await this.getSalesPersonListAction();
    await this.getLeadListAction()
      .then(() => {
        this.totalRows = this.leadList.length;
        this.leads = this.leadList;
        this.leadList.map((v, i) => {
          this.leadName.push({ value: v.id, text: `${v.otherName}` });
          this.leadEmail.push({ value: v.id, text: `${v.email}` });
        });
      })
      .catch((response) => {
        console.log(response);
      });
  },
};
</script>
